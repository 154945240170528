define('carlock-admin/components/forms/shipment-form', ['exports', 'carlock-admin/models/c/purchase/address', 'carlock-admin/models/country', 'carlock-admin/models/state', 'carlock-admin/models/c/purchase/shipment-platform', 'ember-validations'], function (exports, AddressModel, CountryModel, StateModel, ShipmentPlatform, EmberValidations) {

    'use strict';

    exports['default'] = Ember.Component.extend(EmberValidations['default'], {
        showErrors: false,
        platforms: ShipmentPlatform['default'].FIXTURES,
        platform: null,
        country: null,

        countriesList: Ember.computed('platform', function () {
            var p = this.get('platform');
            this.set('shippingAddress.countryIsoCode', null);
            return p ? p.countries : CountryModel['default'].FIXTURES;
        }),
        onPlatformChange: Ember.observer('platform', function () {
            var platform = this.get('platform');
            this.get('items').forEach(function (i) {
                return i.setPlatform(platform);
            });
        }),
        statesList: StateModel['default'].getNiceNames(),
        email: null,
        phoneNumber: null,
        shippingAddress: null,

        priorities: null,
        priority: null,

        //Get from parent
        items: null,
        orderId: null,

        validations: {
            'platform': {
                presence: true
            },
            'email': {
                presence: true,
                email: true
            },
            'phoneNumber': {
                presence: true,
                phone: true
            },
            'shippingAddress.name': {
                presence: true
            },
            'shippingAddress.address': {
                presence: true
            },
            'shippingAddress.city': {
                presence: true
            },
            'shippingAddress.zipCode': {
                presence: true
            },
            'shippingAddress.countryIsoCode': {
                presence: true
            },
            'orderId': {
                presence: true
            },
            'priority': {
                presence: true
            }
        },

        init: function init() {
            this._super.apply(this, arguments);
            this.set('shippingAddress', AddressModel['default'].create());
            this.set('sameAddresses', true);
            this.setPriorities();
        },
        setPriorities: function setPriorities() {
            var priorities = Ember.A();
            ['GROUND', 'ONE_DAY', 'TWO_DAY', 'THREE_DAY'].forEach(function (p) {
                return priorities.push({ id: p, name: p });
            });
            this.set('priorities', priorities);
            this.set('priority', priorities[0]);
        },

        actions: {
            confirm: function confirm() {
                var _this = this;

                this.logger.trace('component:shipment-form: Validating shipment order');
                this.validate().then(function () {
                    _this.logger.trace('component:shipment-form: Creating shipment order');

                    var items = _this.get('items').filter(function (i) {
                        return i.quantity > 0;
                    }).map(function (i) {
                        return {
                            platform_item_id: i.get('id'),
                            quantity: i.quantity
                        };
                    });

                    if (items.length === 0) {
                        _this.logger.trace('component:shipment-form: Items list empty');
                        return;
                    }

                    var shipment = {
                        email: _this.get('email'),
                        address: _this.get('shippingAddress').toJson(),
                        items: items,
                        phone_number: _this.get('phoneNumber'),
                        order_id: _this.get('orderId'),
                        shipment_platform: _this.get('platform.id'),
                        priority: _this.get('priority.id'),
                        distributor_uuid: _this.get('platform.distributor')
                    };

                    _this.sendAction('ship', shipment);
                })['catch'](function (e) {
                    _this.logger.trace('component:shipment-form: Validation failed');
                    _this.set('showErrors', true);
                });
            },
            reset: function reset() {
                this.set('showErrors', false);
                this.set('platform', null);
                this.set('country', null);
                this.set('phoneNumber', null);
                this.set('priority', this.get('priorities')[0]);
                this.set('email', null);
                this.set('shippingAddress', AddressModel['default'].create());
                this.set('sameAddresses', true);
                this.sendAction('reset');
            }
        }
    });

});
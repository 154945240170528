define('carlock-admin/templates/components/lists/cohort-list', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 4,
              "column": 8
            },
            "end": {
              "line": 8,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element24 = dom.childAt(fragment, [1]);
          var morphs = new Array(3);
          morphs[0] = dom.createAttrMorph(element24, 'class');
          morphs[1] = dom.createElementMorph(element24);
          morphs[2] = dom.createMorphAt(element24,1,1);
          return morphs;
        },
        statements: [
          ["attribute","class",["concat",["distributor-filter ",["subexpr","if",[["get","distributor",["loc",[null,[5,48],[5,59]]]],"has-value"],[],["loc",[null,[5,43],[5,73]]]]]]],
          ["element","action",["togglePickDistributorDialog"],[],["loc",[null,[5,75],[5,115]]]],
          ["inline","input",[],["type","input","value",["subexpr","@mut",[["get","distributor.name",["loc",[null,[6,43],[6,59]]]]],[],[]],"placeholder",["subexpr","t",["purchase.list.distributor"],[],["loc",[null,[6,72],[6,103]]]],"readonly",true],["loc",[null,[6,16],[6,119]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child1 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 8
            },
            "end": {
              "line": 22,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","form spinner");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2,"class","dot");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() { return []; },
        statements: [

        ],
        locals: [],
        templates: []
      };
    }());
    var child2 = (function() {
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 8
            },
            "end": {
              "line": 29,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1,"class","not-found");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]),0,0);
          return morphs;
        },
        statements: [
          ["inline","t",["misc.not_found"],[],["loc",[null,[28,35],[28,57]]]]
        ],
        locals: [],
        templates: []
      };
    }());
    var child3 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 24
              },
              "end": {
                "line": 44,
                "column": 24
              }
            },
            "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                            ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("td");
            dom.setAttribute(el1,"class","distributor-column");
            var el2 = dom.createTextNode("\n                                ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("div");
            var el3 = dom.createTextNode("\n                                    ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 1]),1,1);
            return morphs;
          },
          statements: [
            ["inline","t",["devices.distributor"],[],["loc",[null,[41,36],[41,63]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      var child1 = (function() {
        var child0 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 163,
                  "column": 28
                },
                "end": {
                  "line": 165,
                  "column": 28
                }
              },
              "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("td");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() { return []; },
            statements: [

            ],
            locals: [],
            templates: []
          };
        }());
        var child1 = (function() {
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 177,
                  "column": 28
                },
                "end": {
                  "line": 185,
                  "column": 28
                }
              },
              "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n                                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(7);
              morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
              morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
              morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
              morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
              morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
              morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
              morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
              return morphs;
            },
            statements: [
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.utmCountMapFormatted",["loc",[null,[178,58],[178,86]]]]],[],[]]],["loc",[null,[178,32],[178,88]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwToActivationAvgDaysFormatted",["loc",[null,[179,58],[179,97]]]]],[],[]]],["loc",[null,[179,32],[179,99]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwToActivationMedianDaysFormatted",["loc",[null,[180,58],[180,100]]]]],[],[]]],["loc",[null,[180,32],[180,102]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwZeroDaysActivationFormatted",["loc",[null,[181,58],[181,96]]]]],[],[]]],["loc",[null,[181,32],[181,98]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwSevenDaysActivationFormatted",["loc",[null,[182,58],[182,97]]]]],[],[]]],["loc",[null,[182,32],[182,99]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwFourteenDaysActivationFormatted",["loc",[null,[183,58],[183,100]]]]],[],[]]],["loc",[null,[183,32],[183,102]]]],
              ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohorts.wiwMoreDaysActivationFormatted",["loc",[null,[184,58],[184,96]]]]],[],[]]],["loc",[null,[184,32],[184,98]]]]
            ],
            locals: ["popover"],
            templates: []
          };
        }());
        var child2 = (function() {
          var child0 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 230,
                    "column": 32
                  },
                  "end": {
                    "line": 232,
                    "column": 32
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("td");
                var el2 = dom.createElement("div");
                var el3 = dom.createComment("");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]),0,0);
                return morphs;
              },
              statements: [
                ["content","cohortItem.distributorName",["loc",[null,[231,45],[231,75]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child1 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 256,
                    "column": 40
                  },
                  "end": {
                    "line": 258,
                    "column": 40
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                            ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                return morphs;
              },
              statements: [
                ["content","cohortItem.willItWorkUtmSource",["loc",[null,[257,44],[257,78]]]]
              ],
              locals: [],
              templates: []
            };
          }());
          var child2 = (function() {
            var child0 = (function() {
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 258,
                      "column": 40
                    },
                    "end": {
                      "line": 260,
                      "column": 40
                    }
                  },
                  "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                                            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                  return morphs;
                },
                statements: [
                  ["content","cohortItem.willItWorkUtmCampaign",["loc",[null,[259,44],[259,80]]]]
                ],
                locals: [],
                templates: []
              };
            }());
            var child1 = (function() {
              var child0 = (function() {
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 260,
                        "column": 40
                      },
                      "end": {
                        "line": 262,
                        "column": 40
                      }
                    },
                    "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                                            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                    return morphs;
                  },
                  statements: [
                    ["content","cohortItem.willItWorkUtmMedium",["loc",[null,[261,44],[261,78]]]]
                  ],
                  locals: [],
                  templates: []
                };
              }());
              var child1 = (function() {
                var child0 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.3.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 263,
                            "column": 44
                          },
                          "end": {
                            "line": 265,
                            "column": 44
                          }
                        },
                        "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                                                ?\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  var child1 = (function() {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.3.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 265,
                            "column": 44
                          },
                          "end": {
                            "line": 267,
                            "column": 44
                          }
                        },
                        "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                                                ✓\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() { return []; },
                      statements: [

                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 262,
                          "column": 40
                        },
                        "end": {
                          "line": 268,
                          "column": 40
                        }
                      },
                      "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","cohortItem.wiwAfterActivation",["loc",[null,[263,50],[263,79]]]]],[],0,1,["loc",[null,[263,44],[267,51]]]]
                    ],
                    locals: [],
                    templates: [child0, child1]
                  };
                }());
                var child1 = (function() {
                  var child0 = (function() {
                    return {
                      meta: {
                        "fragmentReason": false,
                        "revision": "Ember@2.3.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 268,
                            "column": 40
                          },
                          "end": {
                            "line": 270,
                            "column": 40
                          }
                        },
                        "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                                            ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                                        ");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                        return morphs;
                      },
                      statements: [
                        ["content","cohortItem.extraTrialCode",["loc",[null,[269,44],[269,73]]]]
                      ],
                      locals: [],
                      templates: []
                    };
                  }());
                  return {
                    meta: {
                      "fragmentReason": false,
                      "revision": "Ember@2.3.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 268,
                          "column": 40
                        },
                        "end": {
                          "line": 270,
                          "column": 40
                        }
                      },
                      "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [
                      ["block","if",[["get","cohortItem.extraTrialCode",["loc",[null,[268,50],[268,75]]]]],[],0,null,["loc",[null,[268,40],[270,40]]]]
                    ],
                    locals: [],
                    templates: [child0]
                  };
                }());
                return {
                  meta: {
                    "fragmentReason": false,
                    "revision": "Ember@2.3.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 262,
                        "column": 40
                      },
                      "end": {
                        "line": 270,
                        "column": 40
                      }
                    },
                    "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [
                    ["block","if",[["get","cohortItem.willItWorkDate",["loc",[null,[262,50],[262,75]]]]],[],0,1,["loc",[null,[262,40],[270,40]]]]
                  ],
                  locals: [],
                  templates: [child0, child1]
                };
              }());
              return {
                meta: {
                  "fragmentReason": false,
                  "revision": "Ember@2.3.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 260,
                      "column": 40
                    },
                    "end": {
                      "line": 270,
                      "column": 40
                    }
                  },
                  "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [
                  ["block","if",[["get","cohortItem.willItWorkUtmMedium",["loc",[null,[260,50],[260,80]]]]],[],0,1,["loc",[null,[260,40],[270,40]]]]
                ],
                locals: [],
                templates: [child0, child1]
              };
            }());
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 258,
                    "column": 40
                  },
                  "end": {
                    "line": 270,
                    "column": 40
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [
                ["block","if",[["get","cohortItem.willItWorkUtmCampaign",["loc",[null,[258,50],[258,82]]]]],[],0,1,["loc",[null,[258,40],[270,40]]]]
              ],
              locals: [],
              templates: [child0, child1]
            };
          }());
          var child3 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 271,
                    "column": 40
                  },
                  "end": {
                    "line": 271,
                    "column": 81
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode(" Referred ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child4 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 275,
                    "column": 32
                  },
                  "end": {
                    "line": 283,
                    "column": 32
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n                                    ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(7);
                morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
                morphs[1] = dom.createMorphAt(fragment,3,3,contextualElement);
                morphs[2] = dom.createMorphAt(fragment,5,5,contextualElement);
                morphs[3] = dom.createMorphAt(fragment,7,7,contextualElement);
                morphs[4] = dom.createMorphAt(fragment,9,9,contextualElement);
                morphs[5] = dom.createMorphAt(fragment,11,11,contextualElement);
                morphs[6] = dom.createMorphAt(fragment,13,13,contextualElement);
                return morphs;
              },
              statements: [
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.willItWorkDateFormatted",["loc",[null,[276,62],[276,96]]]]],[],[]]],["loc",[null,[276,36],[276,98]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.willItWorkUtmSourceFormatted",["loc",[null,[277,62],[277,101]]]]],[],[]]],["loc",[null,[277,36],[277,103]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.willItWorkUtmMediumFormatted",["loc",[null,[278,62],[278,101]]]]],[],[]]],["loc",[null,[278,36],[278,103]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.willItWorkUtmCampaignFormatted",["loc",[null,[279,62],[279,103]]]]],[],[]]],["loc",[null,[279,36],[279,105]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.willItWorkGclidFormatted",["loc",[null,[280,62],[280,97]]]]],[],[]]],["loc",[null,[280,36],[280,99]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.wiwToActivationDaysFormatted",["loc",[null,[281,62],[281,101]]]]],[],[]]],["loc",[null,[281,36],[281,103]]]],
                ["inline","dialogs/text-popup",[],["text",["subexpr","@mut",[["get","cohortItem.extraTrialCodeFormatted",["loc",[null,[282,62],[282,96]]]]],[],[]]],["loc",[null,[282,36],[282,98]]]]
              ],
              locals: ["popover"],
              templates: []
            };
          }());
          var child5 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 317,
                    "column": 55
                  },
                  "end": {
                    "line": 317,
                    "column": 97
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("X");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child6 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 318,
                    "column": 55
                  },
                  "end": {
                    "line": 318,
                    "column": 101
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("X");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child7 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 319,
                    "column": 55
                  },
                  "end": {
                    "line": 319,
                    "column": 105
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("X");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          var child8 = (function() {
            return {
              meta: {
                "fragmentReason": false,
                "revision": "Ember@2.3.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 320,
                    "column": 55
                  },
                  "end": {
                    "line": 320,
                    "column": 94
                  }
                },
                "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("X");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() { return []; },
              statements: [

              ],
              locals: [],
              templates: []
            };
          }());
          return {
            meta: {
              "fragmentReason": false,
              "revision": "Ember@2.3.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 227,
                  "column": 24
                },
                "end": {
                  "line": 322,
                  "column": 24
                }
              },
              "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("tr");
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","activated-column");
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","manufacturer-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","model-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","device-type-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","will-it-work-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("Currently not in use\n                                    <td class=\"health-column \">\n                                    <div id=\"{{generate-dom-id cohortItem.vehicleUuid 'health'}}\"\n                                    class=\"health-score {{health cohortItem.healthScore}}\"\n                                    style=\"float: left\"><span>{{cohortItem.healthScore}}</span></div>\n{{#unless cohortItem.health.pushNotificationsDelay}}\n                                        <div id=\"{{generate-dom-id cohortItem.vehicleUuid 'push'}}\" class=\"push-note\">!</div>\n                                        {{#popover-on-element target=(generate-dom-id cohortItem.vehicleUuid '#' 'push') enableLazyRendering=true as |popover| }}\n                                            {{t 'cohort.no_pushes'}}\n                                        {{/popover-on-element}}\n                                    {{/unless}}                                    </td>\n\n{{#popover-on-element target=(generate-dom-id cohortItem.vehicleUuid '#' 'health') enableLazyRendering=true as |popover| }}\n                                        {{dialogs/health-summary-popup cohortItem=cohortItem}}\n                                    {{/popover-on-element}}                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","left-credit-card-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","score-column");
              var el3 = dom.createElement("div");
              var el4 = dom.createTextNode("\n                                        ");
              dom.appendChild(el3, el4);
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n                                    ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","rating-column");
              var el3 = dom.createTextNode("\n                                    ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("div");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n                                ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              var el3 = dom.createElement("div");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","tag-column");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","tag-column");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","tag-column");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                                ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("td");
              dom.setAttribute(el2,"class","tag-column");
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n                            ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var element1 = dom.childAt(element0, [1]);
              var element2 = dom.childAt(element0, [5]);
              var element3 = dom.childAt(element2, [1]);
              var element4 = dom.childAt(element0, [11]);
              var element5 = dom.childAt(element0, [15, 1]);
              var element6 = dom.childAt(element0, [27]);
              var element7 = dom.childAt(element0, [29]);
              var element8 = dom.childAt(element0, [31]);
              var element9 = dom.childAt(element0, [33]);
              var morphs = new Array(27);
              morphs[0] = dom.createAttrMorph(element0, 'class');
              morphs[1] = dom.createElementMorph(element1);
              morphs[2] = dom.createMorphAt(dom.childAt(element1, [0]),0,0);
              morphs[3] = dom.createMorphAt(element0,3,3);
              morphs[4] = dom.createAttrMorph(element2, 'class');
              morphs[5] = dom.createAttrMorph(element3, 'class');
              morphs[6] = dom.createMorphAt(element3,0,0);
              morphs[7] = dom.createMorphAt(dom.childAt(element0, [7, 1]),0,0);
              morphs[8] = dom.createMorphAt(dom.childAt(element0, [9, 1]),0,0);
              morphs[9] = dom.createAttrMorph(element4, 'class');
              morphs[10] = dom.createMorphAt(dom.childAt(element4, [1]),1,1);
              morphs[11] = dom.createMorphAt(dom.childAt(element0, [13, 1]),1,1);
              morphs[12] = dom.createAttrMorph(element5, 'id');
              morphs[13] = dom.createMorphAt(element5,1,1);
              morphs[14] = dom.createMorphAt(element5,3,3);
              morphs[15] = dom.createMorphAt(element0,17,17);
              morphs[16] = dom.createMorphAt(dom.childAt(element0, [21]),1,1);
              morphs[17] = dom.createMorphAt(dom.childAt(element0, [23, 0]),1,1);
              morphs[18] = dom.createUnsafeMorphAt(dom.childAt(element0, [25, 1]),0,0);
              morphs[19] = dom.createAttrMorph(element6, 'class');
              morphs[20] = dom.createAttrMorph(element7, 'class');
              morphs[21] = dom.createAttrMorph(element8, 'class');
              morphs[22] = dom.createAttrMorph(element9, 'class');
              morphs[23] = dom.createMorphAt(dom.childAt(element0, [39]),0,0);
              morphs[24] = dom.createMorphAt(dom.childAt(element0, [41]),0,0);
              morphs[25] = dom.createMorphAt(dom.childAt(element0, [43]),0,0);
              morphs[26] = dom.createMorphAt(dom.childAt(element0, [45]),0,0);
              return morphs;
            },
            statements: [
              ["attribute","class",["concat",[["subexpr","if",[["get","cohortItem.selected",["loc",[null,[228,44],[228,63]]]],"selected"],[],["loc",[null,[228,39],[228,76]]]]]]],
              ["element","action",["select",["get","cohortItem",["loc",[null,[229,54],[229,64]]]]],[],["loc",[null,[229,36],[229,66]]]],
              ["content","cohortItem.activationDateFormatted",["loc",[null,[229,97],[229,135]]]],
              ["block","if",[["subexpr","access-control",["isAdmin"],[],["loc",[null,[230,38],[230,64]]]]],[],0,null,["loc",[null,[230,32],[232,39]]]],
              ["attribute","class",["concat",["email-column ",["subexpr","if",[["get","cohortItem.multiVehicle",["loc",[null,[233,61],[233,84]]]],"multi-vehicle"],[],["loc",[null,[233,56],[233,102]]]]]]],
              ["attribute","class",["concat",[["subexpr","if",[["get","cohortItem.promo",["loc",[null,[234,53],[234,69]]]],"promo"],[],["loc",[null,[234,48],[234,79]]]]]]],
              ["content","cohortItem.userEmail",["loc",[null,[234,81],[234,105]]]],
              ["content","cohortItem.carManufacturer",["loc",[null,[237,41],[237,71]]]],
              ["content","cohortItem.carModel",["loc",[null,[240,41],[240,64]]]],
              ["attribute","class",["concat",["last-seen-column \\\n                                ",["subexpr","if",[["subexpr","not-yet-active",[["get","cohortItem.activationDate",["loc",[null,[243,53],[243,78]]]],["get","cohortItem.lastSeen",["loc",[null,[243,79],[243,98]]]]],[],["loc",[null,[243,37],[243,99]]]],"empty"],[],["loc",[null,[243,32],[243,109]]]]]]],
              ["content","cohortItem.lastSeenFormatted",["loc",[null,[245,40],[245,72]]]],
              ["inline","t",[["get","cohortItem.deviceTypeFormatted",["loc",[null,[250,44],[250,74]]]]],[],["loc",[null,[250,40],[250,76]]]],
              ["attribute","id",["concat",[["subexpr","generate-dom-id",[["get","cohortItem.vehicleUuid",["loc",[null,[255,63],[255,85]]]],"wiw"],[],["loc",[null,[255,45],[255,93]]]]]]],
              ["block","if",[["get","cohortItem.willItWorkUtmSource",["loc",[null,[256,46],[256,76]]]]],[],1,2,["loc",[null,[256,40],[270,47]]]],
              ["block","if",[["get","cohortItem.wasReferred",["loc",[null,[271,46],[271,68]]]]],[],3,null,["loc",[null,[271,40],[271,88]]]],
              ["block","popover-on-element",[],["target",["subexpr","generate-dom-id",[["get","cohortItem.vehicleUuid",["loc",[null,[275,78],[275,100]]]],"#","wiw"],[],["loc",[null,[275,61],[275,111]]]],"enableLazyRendering",true],4,null,["loc",[null,[275,32],[283,55]]]],
              ["inline","t",[["get","cohortItem.subscribedAtActivationPlatformLocaleKey",["loc",[null,[303,40],[303,90]]]]],[],["loc",[null,[303,36],[303,93]]]],
              ["content","cohortItem.successScore",["loc",[null,[306,40],[306,67]]]],
              ["content","cohortItem.ratingFormatted",["loc",[null,[309,41],[309,73]]]],
              ["attribute","class",["concat",["active-column ",["subexpr","if",[["subexpr","equal",["ACTIVE",["get","cohortItem.status",["loc",[null,[311,78],[311,95]]]]],[],["loc",[null,[311,62],[311,96]]]],"active"],[],["loc",[null,[311,57],[311,107]]]]]]],
              ["attribute","class",["concat",["trial-column ",["subexpr","if",[["subexpr","equal",["TRIAL",["get","cohortItem.status",["loc",[null,[312,76],[312,93]]]]],[],["loc",[null,[312,61],[312,94]]]],"trial"],[],["loc",[null,[312,56],[312,104]]]]]]],
              ["attribute","class",["concat",["expired-column ",["subexpr","if",[["subexpr","equal",["EXPIRED",["get","cohortItem.status",["loc",[null,[313,80],[313,97]]]]],[],["loc",[null,[313,63],[313,98]]]],"expired"],[],["loc",[null,[313,58],[313,110]]]]]]],
              ["attribute","class",["concat",["frozen-column ",["subexpr","if",[["subexpr","equal",["FROZEN",["get","cohortItem.status",["loc",[null,[314,78],[314,95]]]]],[],["loc",[null,[314,62],[314,96]]]],"frozen"],[],["loc",[null,[314,57],[314,107]]]]]]],
              ["block","if",[["subexpr","equal",[["get","cohortItem.tag",["loc",[null,[317,68],[317,82]]]],"SECURITY"],[],["loc",[null,[317,61],[317,94]]]]],[],5,null,["loc",[null,[317,55],[317,104]]]],
              ["block","if",[["subexpr","equal",[["get","cohortItem.tag",["loc",[null,[318,68],[318,82]]]],"TEEN_DRIVING"],[],["loc",[null,[318,61],[318,98]]]]],[],6,null,["loc",[null,[318,55],[318,108]]]],
              ["block","if",[["subexpr","equal",[["get","cohortItem.tag",["loc",[null,[319,68],[319,82]]]],"FLEET_MANAGEMENT"],[],["loc",[null,[319,61],[319,102]]]]],[],7,null,["loc",[null,[319,55],[319,112]]]],
              ["block","if",[["subexpr","equal",[["get","cohortItem.tag",["loc",[null,[320,68],[320,82]]]],"OTHER"],[],["loc",[null,[320,61],[320,91]]]]],[],8,null,["loc",[null,[320,55],[320,101]]]]
            ],
            locals: ["cohortItem"],
            templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
          };
        }());
        return {
          meta: {
            "fragmentReason": false,
            "revision": "Ember@2.3.1",
            "loc": {
              "source": null,
              "start": {
                "line": 160,
                "column": 20
              },
              "end": {
                "line": 323,
                "column": 20
              }
            },
            "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("                        ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            dom.setAttribute(el1,"class","month");
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            dom.setAttribute(el2,"id","wiwCount");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" \n");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" / ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                G: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                B: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" / ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                CC:");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" \n                                NCC: ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" \n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                            ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode(" ");
            dom.appendChild(el2, el3);
            var el3 = dom.createElement("br");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                                ");
            dom.appendChild(el2, el3);
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            var el3 = dom.createTextNode("\n                            ");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n                        ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element10 = dom.childAt(fragment, [1]);
            var element11 = dom.childAt(element10, [11]);
            var element12 = dom.childAt(element10, [15]);
            var element13 = dom.childAt(element10, [21]);
            var element14 = dom.childAt(element10, [31]);
            var element15 = dom.childAt(element10, [33]);
            var element16 = dom.childAt(element10, [35]);
            var element17 = dom.childAt(element10, [37]);
            var element18 = dom.childAt(element10, [39]);
            var element19 = dom.childAt(element10, [41]);
            var morphs = new Array(33);
            morphs[0] = dom.createMorphAt(element10,3,3);
            morphs[1] = dom.createMorphAt(element11,0,0);
            morphs[2] = dom.createMorphAt(element11,2,2);
            morphs[3] = dom.createMorphAt(element12,1,1);
            morphs[4] = dom.createMorphAt(element12,5,5);
            morphs[5] = dom.createMorphAt(dom.childAt(element10, [17]),0,0);
            morphs[6] = dom.createMorphAt(dom.childAt(element10, [19]),0,0);
            morphs[7] = dom.createMorphAt(element13,0,0);
            morphs[8] = dom.createMorphAt(element13,2,2);
            morphs[9] = dom.createMorphAt(element13,6,6);
            morphs[10] = dom.createMorphAt(element13,10,10);
            morphs[11] = dom.createMorphAt(dom.childAt(element10, [23]),0,0);
            morphs[12] = dom.createMorphAt(dom.childAt(element10, [25]),0,0);
            morphs[13] = dom.createMorphAt(dom.childAt(element10, [27]),0,0);
            morphs[14] = dom.createMorphAt(dom.childAt(element10, [29]),0,0);
            morphs[15] = dom.createMorphAt(element14,0,0);
            morphs[16] = dom.createMorphAt(element14,2,2);
            morphs[17] = dom.createMorphAt(element15,1,1);
            morphs[18] = dom.createMorphAt(element15,3,3);
            morphs[19] = dom.createMorphAt(element15,7,7);
            morphs[20] = dom.createMorphAt(element16,1,1);
            morphs[21] = dom.createMorphAt(element16,5,5);
            morphs[22] = dom.createMorphAt(element16,7,7);
            morphs[23] = dom.createMorphAt(element17,1,1);
            morphs[24] = dom.createMorphAt(element17,5,5);
            morphs[25] = dom.createMorphAt(element17,7,7);
            morphs[26] = dom.createMorphAt(element18,1,1);
            morphs[27] = dom.createMorphAt(element18,5,5);
            morphs[28] = dom.createMorphAt(element18,7,7);
            morphs[29] = dom.createMorphAt(element19,1,1);
            morphs[30] = dom.createMorphAt(element19,5,5);
            morphs[31] = dom.createMorphAt(element19,7,7);
            morphs[32] = dom.createMorphAt(fragment,3,3,contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [
            ["block","if",[["subexpr","access-control",["isAdmin"],[],["loc",[null,[163,34],[163,60]]]]],[],0,null,["loc",[null,[163,28],[165,35]]]],
            ["content","cohorts.noLocationForLongTimeRatio",["loc",[null,[169,32],[169,70]]]],
            ["content","cohorts.noLocationTotalRatio",["loc",[null,[169,71],[169,103]]]],
            ["content","cohorts.wiwCount",["loc",[null,[172,32],[172,52]]]],
            ["block","popover-on-element",[],["target","#wiwCount","enableLazyRendering",true],1,null,["loc",[null,[177,28],[185,51]]]],
            ["content","cohorts.leftCreditCardCount",["loc",[null,[187,32],[187,63]]]],
            ["content","cohorts.averageUserScore",["loc",[null,[188,32],[188,60]]]],
            ["content","cohorts.goodFeedback",["loc",[null,[189,32],[189,56]]]],
            ["content","cohorts.badFeedback",["loc",[null,[189,59],[189,82]]]],
            ["content","cohorts.goodRatingRatio",["loc",[null,[190,35],[190,62]]]],
            ["content","cohorts.badRatingRatio",["loc",[null,[191,35],[191,61]]]],
            ["content","cohorts.activeUsers",["loc",[null,[193,32],[193,55]]]],
            ["content","cohorts.trialUsers",["loc",[null,[194,32],[194,54]]]],
            ["content","cohorts.expiredUsers",["loc",[null,[195,32],[195,56]]]],
            ["content","cohorts.frozenUsers",["loc",[null,[196,32],[196,55]]]],
            ["content","cohorts.totalUsers",["loc",[null,[197,32],[197,54]]]],
            ["content","cohorts.customers",["loc",[null,[197,57],[197,78]]]],
            ["content","cohorts.ratio",["loc",[null,[199,32],[199,49]]]],
            ["content","cohorts.leftCreditCardRatio",["loc",[null,[200,35],[200,66]]]],
            ["content","cohorts.notLeftCreditCardRatio",["loc",[null,[201,37],[201,71]]]],
            ["content","cohorts.securityTagCount",["loc",[null,[204,32],[204,60]]]],
            ["content","cohorts.securityTagRatio",["loc",[null,[206,32],[206,60]]]],
            ["inline","tooltip-on-element",[],["enableLazyRendering",true,"text",["subexpr","@mut",[["get","cohorts.activeSecurityTagCount",["loc",[null,[207,83],[207,113]]]]],[],[]]],["loc",[null,[207,32],[207,115]]]],
            ["content","cohorts.teenDrivingTagCount",["loc",[null,[210,32],[210,63]]]],
            ["content","cohorts.teenDrivingTagRatio",["loc",[null,[212,32],[212,63]]]],
            ["inline","tooltip-on-element",[],["enableLazyRendering",true,"text",["subexpr","@mut",[["get","cohorts.activeTeenDrivingTagCount",["loc",[null,[213,83],[213,116]]]]],[],[]]],["loc",[null,[213,32],[213,118]]]],
            ["content","cohorts.fleetManagementTagCount",["loc",[null,[216,32],[216,67]]]],
            ["content","cohorts.fleetTagRatio",["loc",[null,[217,32],[217,57]]]],
            ["inline","tooltip-on-element",[],["enableLazyRendering",true,"text",["subexpr","@mut",[["get","cohorts.activeFleetManagementTagCount",["loc",[null,[218,83],[218,120]]]]],[],[]]],["loc",[null,[218,32],[218,122]]]],
            ["content","cohorts.otherTagCount",["loc",[null,[221,32],[221,57]]]],
            ["content","cohorts.otherTagRatio",["loc",[null,[222,32],[222,57]]]],
            ["inline","tooltip-on-element",[],["enableLazyRendering",true,"text",["subexpr","@mut",[["get","cohorts.activeOtherTagCount",["loc",[null,[223,83],[223,110]]]]],[],[]]],["loc",[null,[223,32],[223,112]]]],
            ["block","each",[["get","cohorts.cohortItems",["loc",[null,[227,32],[227,51]]]]],[],2,null,["loc",[null,[227,24],[322,33]]]]
          ],
          locals: ["cohorts"],
          templates: [child0, child1, child2]
        };
      }());
      return {
        meta: {
          "fragmentReason": false,
          "revision": "Ember@2.3.1",
          "loc": {
            "source": null,
            "start": {
              "line": 29,
              "column": 8
            },
            "end": {
              "line": 326,
              "column": 8
            }
          },
          "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("            ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1,"class","selectable-table");
          dom.setAttribute(el1,"cellpadding","0");
          dom.setAttribute(el1,"cellspacing","0");
          var el2 = dom.createTextNode("\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n                    ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","activated-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","email-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","manufacturer-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","model-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","last-seen-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","device-type-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","will-it-work-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" / ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("\n                            <td class=\"health-column\">\n                            <div>\n                            {{t \"cohort.health\"}}\n                            </div>\n                            </td>\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","left-credit-card-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","score-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","rating-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","active-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","trial-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","trial-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","frozen-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","total-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode(" / ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","ration-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"id","tag-security");
          dom.setAttribute(el4,"class","tag-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","tag-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","tag-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("td");
          dom.setAttribute(el4,"class","tag-column");
          var el5 = dom.createTextNode("\n                            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("div");
          var el6 = dom.createTextNode("\n                                ");
          dom.appendChild(el5, el6);
          var el6 = dom.createComment("");
          dom.appendChild(el5, el6);
          var el6 = dom.createTextNode("\n                            ");
          dom.appendChild(el5, el6);
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n                        ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n                    ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n                ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("                ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n            ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element20 = dom.childAt(fragment, [1]);
          var element21 = dom.childAt(element20, [1, 1]);
          var element22 = dom.childAt(element21, [15, 1]);
          var element23 = dom.childAt(element21, [33, 1]);
          var morphs = new Array(24);
          morphs[0] = dom.createMorphAt(dom.childAt(element21, [1, 1]),1,1);
          morphs[1] = dom.createMorphAt(element21,3,3);
          morphs[2] = dom.createMorphAt(dom.childAt(element21, [5, 1]),1,1);
          morphs[3] = dom.createMorphAt(dom.childAt(element21, [7, 1]),1,1);
          morphs[4] = dom.createMorphAt(dom.childAt(element21, [9, 1]),1,1);
          morphs[5] = dom.createMorphAt(dom.childAt(element21, [11, 1]),1,1);
          morphs[6] = dom.createMorphAt(dom.childAt(element21, [13, 1]),1,1);
          morphs[7] = dom.createMorphAt(element22,1,1);
          morphs[8] = dom.createMorphAt(element22,3,3);
          morphs[9] = dom.createMorphAt(dom.childAt(element21, [19, 1]),1,1);
          morphs[10] = dom.createMorphAt(dom.childAt(element21, [21, 1]),0,0);
          morphs[11] = dom.createMorphAt(dom.childAt(element21, [23, 1]),1,1);
          morphs[12] = dom.createMorphAt(dom.childAt(element21, [25, 1]),1,1);
          morphs[13] = dom.createMorphAt(dom.childAt(element21, [27, 1]),1,1);
          morphs[14] = dom.createMorphAt(dom.childAt(element21, [29, 1]),1,1);
          morphs[15] = dom.createMorphAt(dom.childAt(element21, [31, 1]),1,1);
          morphs[16] = dom.createMorphAt(element23,1,1);
          morphs[17] = dom.createMorphAt(element23,3,3);
          morphs[18] = dom.createMorphAt(dom.childAt(element21, [35, 1]),1,1);
          morphs[19] = dom.createMorphAt(dom.childAt(element21, [37, 1]),1,1);
          morphs[20] = dom.createMorphAt(dom.childAt(element21, [39, 1]),1,1);
          morphs[21] = dom.createMorphAt(dom.childAt(element21, [41, 1]),1,1);
          morphs[22] = dom.createMorphAt(dom.childAt(element21, [43, 1]),1,1);
          morphs[23] = dom.createMorphAt(dom.childAt(element20, [3]),1,1);
          return morphs;
        },
        statements: [
          ["inline","t",["vehicle.activation_date"],[],["loc",[null,[35,32],[35,63]]]],
          ["block","if",[["subexpr","access-control",["isAdmin"],[],["loc",[null,[38,30],[38,56]]]]],[],0,null,["loc",[null,[38,24],[44,31]]]],
          ["inline","t",["devices.email"],[],["loc",[null,[47,32],[47,53]]]],
          ["inline","t",["vehicle.settings_manufacturer"],[],["loc",[null,[52,32],[52,69]]]],
          ["inline","t",["vehicle.settings_model"],[],["loc",[null,[57,32],[57,62]]]],
          ["inline","t",["devices.last_seen"],[],["loc",[null,[62,32],[62,57]]]],
          ["inline","t",["vehicle.device_type_version_title"],[],["loc",[null,[67,32],[67,73]]]],
          ["inline","t",["cohort.will_it_work"],[],["loc",[null,[73,32],[73,59]]]],
          ["inline","t",["vehicle.user_promo"],[],["loc",[null,[73,62],[73,88]]]],
          ["inline","t",["cohort.left_credit_card"],[],["loc",[null,[87,32],[87,63]]]],
          ["inline","t",["vehicle.user_success_score"],[],["loc",[null,[92,33],[92,67]]]],
          ["inline","t",["cohort.rating"],[],["loc",[null,[97,32],[97,53]]]],
          ["inline","t",["vehicle.subscription_status.active"],[],["loc",[null,[103,32],[103,74]]]],
          ["inline","t",["vehicle.subscription_status.trial"],[],["loc",[null,[109,32],[109,73]]]],
          ["inline","t",["vehicle.subscription_status.expired"],[],["loc",[null,[115,32],[115,75]]]],
          ["inline","t",["vehicle.subscription_status.frozen"],[],["loc",[null,[121,32],[121,74]]]],
          ["inline","t",["cohort.total"],[],["loc",[null,[127,32],[127,52]]]],
          ["inline","t",["cohort.customers"],[],["loc",[null,[127,55],[127,79]]]],
          ["inline","t",["cohort.ratio"],[],["loc",[null,[132,32],[132,52]]]],
          ["inline","t",["cohort.security"],[],["loc",[null,[137,32],[137,55]]]],
          ["inline","t",["cohort.teen_driving"],[],["loc",[null,[142,32],[142,59]]]],
          ["inline","t",["cohort.fleet_management"],[],["loc",[null,[147,32],[147,63]]]],
          ["inline","t",["cohort.other"],[],["loc",[null,[152,32],[152,52]]]],
          ["block","each",[["get","model",["loc",[null,[160,28],[160,33]]]]],[],1,null,["loc",[null,[160,20],[323,29]]]]
        ],
        locals: [],
        templates: [child0, child1]
      };
    }());
    return {
      meta: {
        "fragmentReason": {
          "name": "triple-curlies"
        },
        "revision": "Ember@2.3.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 332,
            "column": 0
          }
        },
        "moduleName": "carlock-admin/templates/components/lists/cohort-list.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1,"id","cohort-report");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h1");
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","toolbar");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","generate form-button");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","form-button");
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("div");
        dom.setAttribute(el3,"class","clear");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2,"class","table cohort-list");
        var el3 = dom.createTextNode("\n");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element25 = dom.childAt(fragment, [0]);
        var element26 = dom.childAt(element25, [3]);
        var element27 = dom.childAt(element26, [7]);
        var element28 = dom.childAt(element26, [9]);
        var morphs = new Array(11);
        morphs[0] = dom.createMorphAt(dom.childAt(element25, [1]),0,0);
        morphs[1] = dom.createMorphAt(element26,1,1);
        morphs[2] = dom.createMorphAt(element26,3,3);
        morphs[3] = dom.createMorphAt(element26,5,5);
        morphs[4] = dom.createElementMorph(element27);
        morphs[5] = dom.createMorphAt(element27,0,0);
        morphs[6] = dom.createElementMorph(element28);
        morphs[7] = dom.createMorphAt(element28,0,0);
        morphs[8] = dom.createMorphAt(element26,11,11);
        morphs[9] = dom.createMorphAt(dom.childAt(element25, [5]),1,1);
        morphs[10] = dom.createMorphAt(element25,7,7);
        return morphs;
      },
      statements: [
        ["inline","t",["cohort.title"],[],["loc",[null,[2,8],[2,28]]]],
        ["block","if",[["subexpr","access-control",["isAdmin"],[],["loc",[null,[4,14],[4,40]]]]],[],0,null,["loc",[null,[4,8],[8,15]]]],
        ["inline","month-picker",[],["class","date-from","selectedDateChanged","dateFromChanged"],["loc",[null,[10,8],[10,80]]]],
        ["inline","month-picker",[],["class","date-to","selectedDateChanged","dateToChanged"],["loc",[null,[11,8],[11,76]]]],
        ["element","action",["generate"],[],["loc",[null,[13,42],[13,63]]]],
        ["inline","t",["report.generate_button"],[],["loc",[null,[13,64],[13,94]]]],
        ["element","action",["export"],[],["loc",[null,[14,33],[14,52]]]],
        ["inline","t",["misc.export_csv"],[],["loc",[null,[14,53],[14,76]]]],
        ["block","if",[["get","loading",["loc",[null,[16,14],[16,21]]]]],[],1,null,["loc",[null,[16,8],[22,15]]]],
        ["block","if",[["get","noData",["loc",[null,[27,14],[27,20]]]]],[],2,3,["loc",[null,[27,8],[326,15]]]],
        ["inline","dialogs/pick-distributor",[],["id","pick-distributor-dialog","visible",["subexpr","@mut",[["get","showPickDistributorDialog",["loc",[null,[329,68],[329,93]]]]],[],[]],"select","selectDistributor"],["loc",[null,[329,4],[330,32]]]]
      ],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }()));

});
define('carlock-admin/controllers/c/purchases/sim', ['exports', 'ember', 'carlock-admin/mixins/base', 'carlock-admin/models/c/purchase/address', 'carlock-admin/models/country', 'carlock-admin/models/state', 'carlock-admin/models/c/purchase/shipment-item'], function (exports, Ember, Base, AddressModel, CountryModel, StateModel, shipment_item) {

    'use strict';

    exports['default'] = Ember['default'].Controller.extend(Base['default'], {
        items: null,
        orderId: null,

        loadInterface: function loadInterface() {
            this.set('orderId', 'SIM-' + moment().format('YYYY.MM.DD.HHmmSS'));
            this.set('items', shipment_item.SimItems);
        },

        actions: {
            ship: function ship(shipment) {
                var _this = this;

                this.logger.trace('controller:purchase-sim: Creating sim order');

                var prepare = function prepare() {
                    return _this.carlock.sendSim(shipment);
                };

                var success = function success(response) {
                    _this.logger.trace(response);
                    _this.set('visible', false);
                };

                this.save(prepare, success);
            },
            reset: function reset() {
                this.loadInterface();
            }
        }
    });

});